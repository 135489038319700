import './not-found.scss'
import React from 'react'

export default function NotFound() {
  return (
    <div className='not-found'>
      <div className='up'>404</div>
      <div className='down'>NOT FOUND</div>
    </div>
  )
}
